* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", Helvetica, Arial, Verdana, sans-serif;
}
body {
  text-align: center;
  background-color: #F1F1F1;
  color: #fff;
}
.titleH1{
  margin-top: -20px;
  color:#333333 !important;
}
@media (max-width: 514px) {
  .titleH1{
    font-size: 20px;
  }
}

.inputUrl{
  background-color: white !important;
  height: 50px !important;
  font-size: 16px !important;
  color: grey !important;
}
@media (max-width: 514px) {
  .inputUrl{
    text-align: center !important;
    margin-bottom: 10px !important;
  }
}
img {
  display: block;
  width: 480px;
  height: 480px;
  margin: 2rem auto;
  max-width: 97%;
}
@media (max-width: 608px) {
  img {
    max-width: 90% !important;
    max-height: 30vh !important;
  }
}
.app {
  padding: 2rem;
}
h1 {
  font-size: 2rem;
}
input, select {
  appearance: none;
  outline: none;
  border: none;
  background: #EEE;
  width: 100%;
  max-width: 320px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
}
 .listType {
  appearance: none;
  outline: none;
  border: none;
  background: none;
  text-decoration: none;
  color: black !important;
}

.btnGeneration{
  height: 50px !important;
  font-size: 16px !important;
  background: #d11d1d !important;
  border-radius: 30px 30px 30px 30px !important;
  font-weight: 700 !important;
}

.qrCodeDiv{
  display: block;
  margin-top: 25px;
}

.logo{
  height: 150px;
  width: 210px;
}

@media (max-width: 514px) {
  .logo{
    height: 100px;
    width: 150px;
  }

}
.logoDivhart{
  height: 60px;
  width: 140px;
  margin-bottom: 0px;
}

.logoFavicon{
  height: 25px;
  width: 25px;
}

.btnRenitialize{
  background-color: grey !important;
  border-radius: 30px 30px 30px 30px !important;
}

.btnTelechargement{
  border-radius: 30px 30px 30px 30px !important;
  font-weight: 700 !important;
  padding: 12px !important;
}

.titleH3{
  color: #8b8d94 !important;
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.divider{
  color: #d11d1d !important;
  display: flex;
  border-style: solid;
  border-width: 1px;
  width: 50% !important;
  text-align: center !important;
  display: inline-block !important;
  margin-bottom: 15px !important;
}

.typeQr{
  color: black !important;
}

.typeQr .MuiButtonBase-root .MuiTypography-body1{
  font-weight: 700 !important;
  font-size: 18px !important;
}

.iconList{
  background-color: #e6ece6 !important;
  color: #d11d1d !important;
  padding: 10px !important;
  border-radius: 20px 20px 20px 20px !important;
}

.iconList svg{
  font-size: 30px !important;
  font-weight: bold !important;
}


.typeQr .MuiListItemButton-root-root:hover .iconList svg{
  font-size: 32px !important;
}

.activeItem{
background-color: #e6ece6 !important;
}

.activeItem .iconList{
  background-color: #d11d1d !important;
  color: white !important;
  }

.formQrGenerator input{
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px !important;
}

.formQrGenerator input:last-child{
  margin-bottom: 15px !important;
}

.sectionList{
  padding: 0px !important;
}

.iconLists{
  text-decoration: none !important;
  color: black !important;
}

.divhartTag{
  text-decoration: none;
  color: grey ;
}

.footer{
  margin-top: 100px;
}

.socialMediaShare{
}

.socialMediaShare h3{
  margin-top: 70px !important;
  margin-bottom: 20px;
}

.socialMediaShare svg{
  margin-right: 10px;
}

@media all and (display-mode: standalone) {
  #install {
    display: none;
  }
}

#install{
  background: white ;
  padding: 15px;
  border: none;
  border-radius: 0%;
  margin-top: 60px;
}

#install svg{
  background-color: #d11d1d;
  color: white;
  font-size: 35px;
  border-radius: 30px 30px 30px 30px !important;
  margin-right: 10px;
}

#install p{
  color: black;
  font-weight: 700 !important;
  font-size: 19px !important;
}

.emailContact{
  font: normal 20px / 1.6em "Roboto", Helvetica, Arial, Verdana, sans-serif;
  color: #8b8d94;
  word-spacing: normal;
  margin-top: -50px !important;
  text-decoration: none !important;
}